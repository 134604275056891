import axios from "axios";
import store from "../store"

const API_URL = process.env.VUE_APP_API_URL;
const API_WORD = "/entries";

axios.defaults.headers.common["Authorization"] = "Bearer " +  store.getters.getToken;

class EntryDataService {
  getAll() {
    return axios.get(`${API_URL}${API_WORD}`);
  }
  getAllByGroup(groupid) {
    return axios.get(`${API_URL}${API_WORD}/group/${groupid}`);
  }
  getAllForList() {
    return axios.get(`${API_URL}${API_WORD}/list`);
  }
  delete(id) {
    return axios.delete(`${API_URL}${API_WORD}/${id}`);
  }
  getById(id) {
    return axios.get(`${API_URL}${API_WORD}/${id}`);
  }
  update(id, data) {
    return axios.put(`${API_URL}${API_WORD}/${id}`, data);
  }
  create(data) {
    return axios.post(`${API_URL}${API_WORD}`, data);
  }
}

export default new EntryDataService();

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="entries"
      sort-by="name"
      class="elevation-1"
      dense
      v-model="selectedRows"
      :search="filter"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Einträge</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-card-title>
            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="suchen"
              single-line
              hide-details
            />
          </v-card-title>
          <v-spacer/>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left" />
                <v-divider class="mx-1" />
                <v-container>
                  <v-form ref="form_entryliste" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.name"
                          v-model="editedItem.name"
                          label="Name"
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.right"
                          v-model="editedItem.right"
                          label="Recht"
                          :rules="rules.right"
                          hint="Vorhandene Rechte: ais_user, ais_admin, ais_admin_operator"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                            :items="groups"
                            v-model="editedItem.grouping"
                            label="Gruppe"
                            item-text="name"
                            item-value="id"
                            :rules="rules.group"
                            return-object
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                         <v-text-field
                          :value="editedItem.url"
                          v-model="editedItem.url"
                          :rules="rules.url"
                          label="URL"
                        />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                         <v-text-field
                          :value="editedItem.pictureURL"
                          v-model="editedItem.pictureURL"
                          :rules="rules.pictureurl"
                          label="Bild-URL"
                          hint="Die Bilder sollten im S3-Data-Stoarage abgelegt werden"
                        />
                        </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close">
                  Abbruch
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Möchten Sie die Eintrag entfernen?</v-card-title
              >
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="closeDelete"
                  >Abbruch</v-btn
                >
                <v-btn color="primary" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" color="error"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <span>{{ formatDate(item.created) }}</span>
      </template>
      <template v-slot:[`item.updated`]="{ item }">
        <span>{{ formatDate(item.updated) }}</span>
      </template>
      <template v-slot:[`item.grouping`]="{ item }">
        <span>{{ formatGroup(item.grouping) }}</span>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <span>{{ formatURL(item.url) }}</span>
      </template>
      <template v-slot:[`item.pictureURL`]="{ item }">
        <span>{{ formatURL(item.pictureURL) }}</span>
      </template>
    </v-data-table>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn @click="initialize" outlined> Neu laden </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="darklagoon">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amarillo" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EntryDataService from "../services/EntryDataService.js";
import GroupDataService from "../services/GroupDataService.js";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id", filterable: false },
      { text: "Name", value: "name", filterable: true },
      { text: "Recht", value: "right", filterable: true},
      { text: "Gruppe", value: "grouping", filterable: true },
      { text: "Bild-URL", value: "pictureURL", dataType: "URL", filterable: true },
      { text: "URL", value: "url", dataType: "URL", filterable: true },
      { text: "Erstellt", value: "created", dataType: "Date", filterable: false },
      { text: "letztes Update", value: "updated", dataType: "Date", filterable: false },
      { text: "Aktionen", value: "actions", sortable: false, filterable: false },
    ],
    rules: {
        name: [v => !!v || "Name erforderlich"],
        right: [v => !!v || "Recht erforderlich"],
        url: [v => !!v || "URL erforderlich"],
        pictureurl: [v => !!v || "Bild erforderlich"],
        group: [v => !!v || "Gruppe erforderlich" ]
      },
    entry: [],
    entries: [],
    groups: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      right: "",
      grouping: "",
      id: "",
      pictureURL: "",
      url: "",
    },
    defaultItem: {
      name: "",
      right: "",
      grouping: "",
      id: "",
      pictureURL: "",
      url: "",
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 8000,
    selectedRows: [],
    filter: "",
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Eintrag hinzufügen"
        : "Eintrag ändern";
    },
    formDescription() {
      return "Bitte hier die entsprechenden Daten zu einem Eintrag erfassen.</br>Berücksichtigen Sie dabei bitte das Recht das für die Sichtbarkeit eines Eintrags benötigt wird.";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadEntries(true);
      this.loadGroups(false);
    },
    async loadEntries(ismessage) {
      let resp = await EntryDataService.getAll();
      if (resp.status == 200) {
        this.entries = resp.data;
        if (ismessage == true) {
          this.message = "Eintrag gelesen.";
          this.snackbar = true;
        }
      } else {
        this.message = resp.data;
        if (!this.message) {
          this.message = "Keine Daten vorhanden";
        }
        this.snackbar = true;
      }
    },
    async loadGroups(ismessage) {
      let resp = await GroupDataService.getAll();
      if (resp.status == 200) {
        this.groups = resp.data;
        if (ismessage == true) {
          this.message = "Eintrag gelesen.";
          this.snackbar = true;
        }
      } else {
        this.message = resp.data;
        if (!this.message) {
          this.message = "Keine Daten vorhanden";
        }
        this.snackbar = true;
      }
    },
    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.entries.splice(this.editedIndex, 1);
      this.closeDelete();
      this.deleteService();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.updateService();
        } else {
          this.addService();
        }
        this.close();
      }
    },
    getEntry(value) {
      this.loadEntry(value);
    },
    async loadEntry(value) {
      let resp = await EntryDataService.getById(value);
      this.Entry = resp.data;
      if (resp.status == 200) {
        this.message = "Eintrag gelesen.";
        this.snackbar = true;
      } else {
        this.message = resp.data;
        this.snackbar = true;
      }
    },
    async addService() {
      try {
        let resp = await EntryDataService.create(this.editedItem);
        if (resp.status == 201) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async updateService() {
      try {
        let resp = await EntryDataService.update(
          this.editedItem.id,
          this.editedItem
        );
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async deleteService() {
      try {
        let resp = await EntryDataService.delete(this.editedItem.id);
        if (resp.status == 204) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadEntries(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    validate() {
      this.$refs.form_entryliste.validate();
    },
    formatDate(item) {
      if (item === null) {
        return "--- ";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      return new Date(item).toLocaleString("de-DE", options);
    },
    formatURL(item) {
      if (item === null) {
        return "--- ";
      }
      if(item.length > 30){
        return "...".concat(item.substring(item.length-30));
      }
      return item;
    },
    formatGroup(group) {
      if (group) {
        var groupId = group.id;
        if(groupId){
          var find = this.groups.find(function(item) {
            return item.id === groupId;
          });
          if (find){
            return find.name;
          }
          return "";
        }
      }
    },
  },
};
</script>
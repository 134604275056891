<template>
<div>
  <v-tabs v-model="tab">
    <v-tab v-for="(item, index) in datamodel"
          :key="index">{{ item.group.name }}</v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, index) in datamodel"
        :key="index"
      >
      <v-card
          flat
        >
          <v-card-text>
              <div id="myLinklist" v-html="formatList(item.entries).outerHTML">
                  &nbsp;
              </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
     <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn @click="initialize" outlined> Neu laden </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="darklagoon">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amarillo" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
</div>
</template>

<script>
import EntryDataService from "../services/EntryDataService.js";
export default {
  data: () => ({
    datamodel: [],
    tab: null,
    snackbar: false,
    message: "",
    snackbar_timeout: 8000,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadData(true);
    },
    async loadData(ismessage) {
      let resp = await EntryDataService.getAllForList();
      if (resp.status == 200) {
        this.datamodel = resp.data;
        if (ismessage == true) {
          if(!this.datamodel){
            this.message = "Keine Daten vorhanden";
          }
          else {
            this.message = "Daten gelesen.";
          }
          this.snackbar = true;
        }
      } else {
        this.message = "Fehler beim Lesen der Daten (" + resp.status + ")";
        this.snackbar = true;
      }
    },
    formatList(jsondata){
        var listdiv = document.createElement("div");
        listdiv.classList.add("mylink-flex");
        for (var i = 0; i < jsondata.length; i++) {
            var link = document.createElement("a");
            link.href = jsondata[i]["url"];
            link.target = "_blank";
            link.classList.add("text-midnight");
            link.classList.add("mylink-a");
            var picture = document.createElement("img");
            picture.src = jsondata[i]["pictureURL"];
            var span = document.createElement("span");
            span.textContent = jsondata[i]["name"];
            var divtop = document.createElement("div");
            divtop.classList.add("mylink-topdiv");
            divtop.appendChild(picture);
            var divbottom = document.createElement("div");
            divbottom.classList.add("mylink-buttomdiv");
            divbottom.appendChild(span)
            var div = document.createElement("div");
            div.classList.add("mylink-div");
            div.appendChild(divtop);
            div.appendChild(divbottom);
            link.appendChild(div);
            listdiv.appendChild(link);
        }
        return listdiv;
    },
  }
};
</script>

<style>
.mylink-flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.mylink-div {
    margin: 10px;
    padding: 25px;
    height: 200px;
    width: 200px;
    background: #6dd1cb;
    border: 1px solid #009390;
    text-align: center;
}

.mylink-div:hover {
    background: #99e0dc;
}

.mylink-flex img {
    max-height: 100px;
    max-width: 100px;
    margin-bottom: 15px;
}

.mylink-topdiv {
    width: 100%;
    height: 115px;
}

.mylink-buttomdiv {
    width: 100%;
    height: 50px;
}

.mylink-a{
    text-decoration: none;
}
</style>
